<template>
  <FormCard v-if="pp" :config="general_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import { Query, Predicate } from "@syncfusion/ej2-data";
import FormCard from "../../../common/form/Card";
import GetOptions from "../../../common/form/options";

export default {
  model: {
    prop: "pp",
    event: "change",
  },
  props: ["pp", "cur_lang"],
  components: {
    FormCard,
  },
  watch: {
    "pp.category__macrocategory_id": function (nV, oV) {
      console.log("changed macrocategory", nV, oV);
      // this.UpdateMacrocategoryOptions();
      this.pp.category__parent_id = null;
      // this.UpdateLtypeOptions();
      this.pp.ptype_id = null;
    },
    "pp.category__parent_id": function (nV, oV) {
      console.log("changedparent", nV, oV);
      // this.UpdateCategoryOptions();
      this.pp.category_id = null;
    },
  },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      this.$emit("change", data);
    },
  },
  computed: {
    general_data() {
      return {
        title: this.$t("role.product.card_data.title"),
        data: this.pp,
        cur_lang: this.cur_lang,
        fields: {
          name: {
            title: this.$t("models.productproducer.name"),
            description: this.$t("models.productproducer.name_info"),
            type: "CInput",
            size: 12,
            subsize: [2, 10],
            translate: "translations",
          },
          description: {
            title: this.$t("models.productproducer.description"),
            description: this.$t("models.productproducer.description_info"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            translate: "translations",
          },
          production_type_id: {
            title: this.$t("models.productproducer.production_type"),
            description: this.$t("models.productproducer.production_type_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_production"),
              query_cache: "structure_production",
              cur_lang: this.cur_lang,
            }),
          },
          ptype_id: {
            title: this.$t("models.productproducer.ptype_id"),
            description: this.$t("models.productproducer.ptype_id_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_ltype"),
              query: new Query().where(
                "macrocategory_id",
                "equal",
                this.pp.category__macrocategory_id
              ),
              query_cache:
                "structure_ltype_" + this.pp.category__macrocategory_id,
              cur_lang: this.cur_lang,
            }),
          },
          category__macrocategory_id: {
            title: this.$t("models.productproducer.category__macrocategory_id"),
            description: this.$t(
              "models.productproducer.category__macrocategory_id_info"
            ),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_ptype"),
              query_cache: "structure_ptype",
              cur_lang: this.cur_lang,
            }),
          },
          category__parent_id: {
            title: this.$t("models.productproducer.category__parent_id"),
            description: this.$t(
              "models.productproducer.category__parent_id_info"
            ),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_category_browser"),
              query: new Query().where(
                new Predicate("parent_id", "equal", null).and(
                  "macrocategory_id",
                  "equal",
                  this.pp.category__macrocategory_id
                )
              ),
              query_cache:
                "structure_category_browser_M" +
                this.pp.category__macrocategory_id,
              cur_lang: this.cur_lang,
            }),
          },
          category_id: {
            title: this.$t("models.productproducer.category_id"),
            description: this.$t("models.productproducer.category_id_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_category_browser"),
              query: new Query().where(
                new Predicate("parent_id", "equal", this.pp.category__parent_id)
                  .and(
                    "macrocategory_id",
                    "equal",
                    this.pp.category__macrocategory_id
                  )
                  .and("parent_id", "greaterthan", 0)
              ),
              query_cache:
                "structure_category_browser_M" +
                this.pp.category__macrocategory_id +
                "_P" +
                this.pp.category__parent_id,
              cur_lang: this.cur_lang,
            }),
          },
          certifications: {
            title: this.$t("models.productproducer.certifications"),
            description: this.$t("models.productproducer.certifications_info"),
            type: "CInputCheckboxNested",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_certification"),
              reducer_cache: "certifications_macro",
              reducer: function (map, obj) {
                map.push({
                  value: obj.id,
                  label: obj.name,
                  macro_id: obj.macrocategory_id,
                });
                return map;
              },
            }),
            master_options: GetOptions({
              dm: GetDataManager("structure_certification_macro"),
              reducer_cache: "certifications_macro",
            }),

            search: true,
          },
          ageclass_id: {
            title: this.$t("models.productproducer.ageclass_id"),
            description: this.$t("models.productproducer.ageclass_id_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("structure_ageclass"),
              query_cache: "structure_ageclass",
              cur_lang: this.cur_lang,
            }),
          },
          tax_rate_id: {
            title: this.$t("models.productproducer.tax_rate_id"),
            description: this.$t("models.productproducer.tax_rate_id_info"),
            type: "CSelect",
            size: 12,
            subsize: [2, 10],
            options: GetOptions({
              dm: GetDataManager("geo_tax"),
              query: new Query().where(
                "country_id",
                "equal",
                this.$store.state.role.country_id
              ),
              query_cache: "geo_tax_" + this.$store.state.role.country_id,
            }),
          },
        },
        dm: GetDataManager("role_producer_products", [
          this.$store.state.role.id,
        ]),
      };
    },
  },
};
</script>
