<template>
  <CButtonGroup
    v-c-tooltip.hover="{
      content: `Seleziona le lingue in cui vuoi localizzare i dati`,
      color: `secondary`,
    }"
  >
    <CButton
      v-for="lang in languages"
      :key="lang.language"
      variant="outline"
      color="secondary"
      size="sm"
      :pressed="lang.language == clang ? true : false"
      v-on:click="$emit('change', lang.language)"
    >
      <CIcon :content="lang.flag" />
    </CButton>
  </CButtonGroup>
</template>
<script>
import { languages } from "../config/global";

export default {
  model: {
    prop: "clang",
    event: "change",
  },
  props: ["clang"],
  data() {
    return {
      languages,
    };
  },
};
</script>
