<template>
  <FormCard v-if="pp" :config="imgs_data" @DataSaved="UpdateData" />
  <!-- @data_updated="UpdateData"
    @field_changed="FieldChanged" -->
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";

export default {
  model: {
    prop: "pp",
    event: "change",
  },
  props: ["pp", "cur_lang"],
  components: {
    FormCard,
  },

  watch: {
    // "pp.category__macrocategory_id": function (nV, oV) {
    //   console.log("changed macrocategory", nV, oV);
    //   // this.UpdateMacrocategoryOptions();
    //   this.pp.category__parent_id = null;
    //   // this.UpdateLtypeOptions();
    //   this.pp.ptype_id = null;
    // },
    // "pp.category__parent_id": function (nV, oV) {
    //   console.log("changedparent", nV, oV);
    //   // this.UpdateCategoryOptions();
    //   this.pp.category_id = null;
    // },
  },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      this.$emit("change", data);
    },
  },
  computed: {
    imgs_data() {
      return {
        title: this.$t("role.product.card_imgs.title"),
        data: this.pp,
        fields: {
          base_img: {
            title: this.$t("models.productproducer.image"),
            description: this.$t("models.productproducer.image_info"),
            type: "Cropper",
            size: 12,
            subsize: [12, 12],
            ratio: 800 / 700,
            image: "image",
            image_cropping: "image_cropping",
            image_cropped: "image_cropped",
            circle: false,
          },
          label_img: {
            title: this.$t("models.productproducer.image_label"),
            description: this.$t("models.productproducer.image_label_info"),
            type: "Cropper",
            size: 12,
            subsize: [12, 12],
            image: "image_label",
            image_cropping: "image_label_cropping",
            image_cropped: "image_label_cropped",
            circle: false,
          },
        },
        dm: GetDataManager("role_producer_products", [
          this.$store.state.role.id,
        ]),
      };
    },
  },
};
</script>
