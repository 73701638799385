<template>
  <CCard>
    <CCardHeader v-if="pp">
      <div class="card-header-actions text-right">
        <div v-if="ppls.active > 0" class="mt-2">
          <b v-if="ppls.min_price == ppls.max_price" class="product_name"
            >{{ ppls.max_price }} CHF</b
          >
          <b v-else class="product_name"
            >{{ ppls.min_price }} - {{ ppls.max_price }} CHF</b
          >
        </div>
      </div>
      <div style="display: flex; flex-direction: row">
        <div class="mr-4 mt-2">
          <CSwitch
            v-if="!active_loading"
            color="success"
            data-on="On"
            data-off="Off"
            :checked.sync="pp.is_active"
            v-c-tooltip.hover="{
              content: $t('role.product.btn_activate'),
            }"
          />
          <CSpinner v-else color="success" size="sm" />
        </div>
        <div>
          <b class="product_name">{{ pp.translations[cur_lang].name }}</b>
          <br />
          PP{{ pp.id }} -
          {{ $t("role.products.showcase.product.lots_active") }}
          {{ pp.ext__lots_active }},
          {{ $t("role.products.showcase.product.lots_inactive") }}
          {{ pp.ext__lots_inactive }}
        </div>
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <languageEditSelector class="float-right mx-1" v-model="cur_lang" />
          <CButton
            class="float-right mx-1"
            color="secondary"
            size="sm"
            @click="pp_show_detail = !pp_show_detail"
          >
            {{ $t("role.product.btn_details") }}
          </CButton>
        </CCol>
      </CRow>
      <CRow v-if="pp && !pp_show_detail">
        <CCol md="2">
          <img
            v-if="pp.image_cropped"
            v-bind:src="pp.image_cropped"
            class="img-fluid"
          />
        </CCol>
        <CCol md="10">
          <div v-html="pp.translations[cur_lang].description" />
        </CCol>
      </CRow>
      <CRow v-else-if="pp && pp_show_detail">
        <CCol md="2">
          <ProductPPimgs v-model="pp" :cur_lang="cur_lang" />
        </CCol>
        <CCol md="10">
          <ProductPPdata v-model="pp" :cur_lang="cur_lang" />
        </CCol>
        <!-- <CCol md="3">
          <ImageCropper :config="img_cropper" @img_updated="UpdatePPdata" />
          <ImageCropper :config="label_cropper" @img_updated="UpdatePPdata" />
        </CCol> -->
      </CRow>
      <div v-else class="loading">
        <CSpinner color="secondary" size="lg" />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import languageEditSelector from "../../../common/languageEditSelector";
import { GetDataManager } from "../../../ds/index";
import ProductPPdata from "./ProductPPdata";
import ProductPPimgs from "./ProductPPimgs";

export default {
  data() {
    return {
      cur_lang: this.$i18n.locale,
      pp_show_detail: false,
      active_loading: false,
      pp: this.bpp,
    };
  },
  model: {
    prop: "bpp",
    event: "change",
  },
  props: ["bpp", "ppls"],
  components: {
    languageEditSelector,
    ProductPPdata,
    ProductPPimgs,
  },
  watch: {
    bpp: function () {
      this.pp = this.bpp;
    },
    "pp.is_active": function (Nv, Ov) {
      //   console.log("active", Nv, Ov);
      if (typeof Ov !== "undefined") {
        this.active_loading = true;
        let params = {
          id: this.pp.id,
          is_active: this.pp.is_active,
        };
        let self = this;
        GetDataManager("role_producer_products", [this.$store.state.role.id])
          .update("", params)
          .then(
            (e) => {
              //   self.pp = e;
              self.$emit("change", e);

              self.active_loading = false;

              this.$store.dispatch("toaster/add", {
                title: this.$t("role.product.toast.status.title"),
                text: this.$t("role.product.toast.status.text"),
                autohide: true,
              });
            },
            () => {
              this.pp.is_active = !this.pp.is_active;
              this.active_loading = false;
              // this.dataForm.errors = errors;
              // this.dataForm.loading = false;
            }
          );
      }
    },
  },
};
</script>

<style scoped>
.product_name {
  font-size: 2em;
}
.product_price {
  font-size: 1.5em;
}
</style>
